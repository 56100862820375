import { RuntimeConfig } from '@nuxt/schema'
import { FetchError } from 'ofetch'
import { LocationQuery } from 'vue-router'
import NotFoundError from '../app/error/NotFoundError'
import ViewProductResponse from '~/models/Api/ViewProductResponse'
import ProductAddonSaleAreasResponsePart from '~/models/Api/ProductAddonSaleAreasResponsePart'
import ProductSimilarProductsResponse from '~/models/Api/ProductSimilarProductsResponse'
import ProductRatingResponse from '~/models/Api/ProductRatingResponse'
import SelectedDiscussionList from '~/models/Api/SelectedDiscussionList'
import DiscussionGetWatchdog from '~/models/Api/DiscussionGetWatchdog'
import LikePost from '~/models/Discussion/LikePost'
import DisLikePost from '~/models/Discussion/DisLikePost'
import FavoriteInsertOrDeleteResponse from '~/models/Api/FavoriteInsertOrDeleteResponse'
import Review from '~/models/ReviewClub/Review'
import ProductAddonSaleBundleResponse from '~/models/Api/ProductAddonSaleBundleResponse'
import GiftEventProductsResponse from '~/models/Api/GiftEventProductsResponse'
import ReportRequestBody from '~/models/Discussion/ReportRequestBody'
import ProductFrequentlyActualizedData from '~/models/Api/ProductFrequentlyActualizedData'
import ProductFrequentlyActualizedDataCollection from '~/models/Api/ProductFrequentlyActualizedDataCollection'
import CommentAggregateResponse from '~/models/Api/CommentAggregateResponse'
import { AddonSaleBundleStrategy } from '~/models/Product/AddonSaleBundleStrategy'
import ViewCounsellingDetailResponse from '~/models/Api/Counselling/ViewCounsellingDetailResponse'
import ViewCounsellingHomepageResponse from '~/models/Api/Counselling/ViewCounsellingHomepageResponse'
import ViewCounsellingListResponse from '~/models/Api/Counselling/ViewCounsellingListResponse'
import ViewCounsellingListRequest from '~/models/Api/Counselling/ViewCounsellingListRequest'
import UpsertCounsellingPostRequest from '~/models/Api/Counselling/UpsertCounsellingPostRequest'
import UpsertCounsellingPostResponse from '~/models/Api/Counselling/UpsertCounsellingPostResponse'
import BasketProductCarouselsResponse from '~/models/Api/BasketProductCarouselsResponse'
import AddBasketVoucherSuccessResponse from '~/models/Api/AddBasketVoucherSuccessResponse'
import AddBasketVoucherErrorResponse from '~/models/Api/AddBasketVoucherErrorResponse'
import ViewBasketResponse from '~/models/Api/ViewBasketResponse'
import ViewPharmacyListRequest from '~/models/Api/Pharmacy/ViewPharmacyListRequest'
import ViewPharmacyListResponse from '~/models/Api/Pharmacy/ViewPharmacyListResponse'
import GeneralResponse from '~/models/Api/GeneralResponse'
import ViewPharmacyDetailResponse from '~/models/Api/Pharmacy/ViewPharmacyDetailResponse'
import ViewPharmacyProductsAvailabilityResponse from '~/models/Api/Pharmacy/ViewPharmacyProductsAvailabilityResponse'
import ComplaintHomepageResponse from '~/models/Api/Complaints/ComplaintHomepageResponse'
import ComplaintDetailResponse from '~/models/Api/Complaints/ComplaintDetailResponse'
import ComplaintUpsertResponse from '~/models/Api/Complaints/ComplaintUpsertResponse'
import OrdersForComplaintResponse from '~/models/Api/Complaints/OrdersForComplaintResponse'
import UpsertComplaintRequest from '~/models/Complaint/UpsertComplaintRequest'
import ComplaintFindOrderResponse from '~/models/Api/Complaints/ComplaintFindOrderResponse'
import SlotsResponse from '~/models/Api/SlotsResponse'
import ComplaintSettingsResponse from '~/models/Api/Complaints/ComplaintSettingsResponse'
import ViewStaticContentResponse from '~/models/Api/ViewStaticContentResponse'
import ViewOrderSentResponse from '~/models/Api/Cart/ViewOrderSentResponse'
import ViewArticleListResponse from '~/models/Api/Article/ViewArticleListResponse'
import PopUpRegistrationResponse from '~/models/Api/PopUpRegistrationResponse'
import DiscussionUpsertRequest from '~/models/Api/DIscussion/DiscussionUpsertRequest'
import Product from '~/models/Product/Product'
import WatchdogProductResponse from '~/models/Api/WatchdogProductResponse'
import ListLastBoughtRecommendedProductsResponse from '~/models/Api/ListLastBoughtRecommendedProductsResponse'
import InvalidAccessTokenException from '~/models/InvalidAccessTokenException'
import AddonSaleProductsResponse from '~/models/Api/AddonSaleProductsResponse'
import CreateBasketUserRequest from '~/models/Api/Cart/CreateBasketUserRequest'
import CartMiddlewareResponse from '~/models/Api/CartMiddlewareResponse'
import ValidateBasketPostCodeResponse from '~/models/Api/ValidateBasketPostCodeResponse'
import ViewBasketDeliveryDateTextResponse from '~/models/Api/ViewBasketDeliveryDateTextResponse'
import PwaShopBannersResponse from '~/models/Api/PwaShopBannersResponse'
import AltDeliverySlotsResponse from '~/models/Slot/AltDeliverySlotsResponse'

interface AddCommentRequest {
    accessToken: string | null
    objectId: number
    name: string
    email: string
    text: string
    rating: number
    questionRatings: any
    pros: string[]
    cons: string[]
    type: number
    feedbackHash: string | null
    uid: string | null
}

interface SendFeedbackRequest {
    accessToken: string
    rating: number
    uid: string
    feedbackHash: string
    objectId: number
    type: number
    text: string
}

interface UpdateRaiffeisenDiscountCheckboxesRequest {
    uid: string
    isCheckboxEnabled: boolean
    isMarketingNotificationsCheckboxEnabled: boolean
}
interface UpdateRaiffeisenDiscountCheckboxesRequestHeaders {
    accessToken: string
}

export default class Repository {
    private config: RuntimeConfig
    private sentry

    constructor(config: RuntimeConfig, sentry: any) {
        this.config = config
        this.sentry = sentry
    }

    constructUrl(url: string): string {
        if (process.client) {
            return `${this.config.public.apiUrl}${url}`
        } else {
            return `${this.config.public.apiUrlInsideDocker}${url}`
        }
    }

    async getCustomAddonSaleCarousels() {
        const url = this.constructUrl(`/api/v2/product/custom-addon-sale-carousels`)
        const response = await fetch(url)

        if (response.status === 200) {
            return (await response.json()).response
        } else {
            throw new Error('Addon salses data could not be loaded.')
        }
    }

    async getProductAddonSaleAreas(productId: number): Promise<ProductAddonSaleAreasResponsePart[]> {
        const url = this.constructUrl(`/api/v2/product/addon-sale-areas?productId=${productId}`)
        const response = await fetch(url)

        if (response.status === 200) {
            return (await response.json()).response
        } else {
            throw new Error('Addon salses data could not be loaded.')
        }
    }

    async getProductFrequentlyActualizedData(productIds: number[]): Promise<ProductFrequentlyActualizedDataCollection> {
        const headers = {
            platform: 'pwa',
        }
        const response = await this.getData(
            `/api/v2/product/frequently-actualized-data`,
            {
                ids: productIds,
            },
            headers
        )
        const dataAsArray: ProductFrequentlyActualizedData[] = response.products

        const collection: ProductFrequentlyActualizedDataCollection = {}
        dataAsArray.forEach((data) => {
            collection[data.id] = data
        })

        return collection
    }

    async getProductSimilarProducts(productId: number, minAmountInStock: number): Promise<ProductSimilarProductsResponse> {
        const url = this.constructUrl(`/api/v2/product/similar?productId=${productId}&minAmountInStock=${minAmountInStock}`)
        const response = await fetch(url)

        if (response.status === 200) {
            return (await response.json()).response
        } else {
            throw new Error('Similar products data could not be loaded.')
        }
    }

    async getAddonSaleProducts(uid: string): Promise<GeneralResponse<AddonSaleProductsResponse>> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getDataWithGeneralResponse(
            `/api/v2/basket/addon-sale-product/list`,
            {
                uid,
            },
            headers
        )
    }

    async getSlotsForAltDelivery(zip: string, uid: string): Promise<GeneralResponse<AltDeliverySlotsResponse>> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getDataWithGeneralResponse(
            `/api/v2/car/pilulka-slots-for-alt-delivery`,
            {
                zip,
                uid,
            },
            headers
        )
    }

    async changeDeliveryToInternal(slot: string, uid: string, zip: string): Promise<GeneralResponse<AltDeliverySlotsResponse>> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getDataWithGeneralResponse(
            `/api/v2/basket/change-to-internal-delivery`,
            {
                slot,
                zip,
                uid,
            },
            headers
        )
    }

    async getCounsellingDetail(counsellingPostId: number): Promise<ViewCounsellingDetailResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            `/api/v2/counselling/detail`,
            {
                id: counsellingPostId,
            },
            headers
        )
    }

    async getCounsellingHomepage(): Promise<ViewCounsellingHomepageResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(`/api/v2/counselling/homepage`, {}, headers)
    }

    async getCounsellingList(request: ViewCounsellingListRequest): Promise<ViewCounsellingListResponse> {
        return await this.getData('/api/v2/counselling/list', request)
    }

    async upsertCounsellingPost(request: UpsertCounsellingPostRequest): Promise<UpsertCounsellingPostResponse> {
        return await this.getData('/api/v2/counselling/upsert', request)
    }

    async getPharmacyList(request: ViewPharmacyListRequest): Promise<GeneralResponse<ViewPharmacyListResponse>> {
        return await this.getDataWithGeneralResponse('/api/v3/pharmacy/list', request)
    }

    async getPharmacyDetail(pharmacyId: number): Promise<GeneralResponse<ViewPharmacyDetailResponse>> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getDataWithGeneralResponse<ViewPharmacyDetailResponse>(
            `/api/v3/pharmacy/view`,
            {
                id: pharmacyId,
            },
            headers
        )
    }

    async getOrderSentDetail(orderNumberToken: string): Promise<ViewOrderSentResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(`/api/v2/order/sent`, { orderNumberToken }, headers)
    }

    async sendTargitoS2S(orderNumberToken: string, trgid: string, trgm: string, trgd: string): Promise<ViewOrderSentResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            `/api/v2/order/targito-s2s`,
            {
                orderNumberToken,
                trgid,
                trgm,
                trgd,
            },
            headers
        )
    }

    async getComplaintHomepage(accessToken: string): Promise<ComplaintHomepageResponse> {
        const headers = {
            platform: 'pwa',
            accessToken,
        }

        return await this.getData(`/api/v2/complaint/homepage`, {}, headers)
    }

    async getComplaintDetail(accessToken: string, complaintNumber: string): Promise<GeneralResponse<ComplaintDetailResponse>> {
        const headers = {
            platform: 'pwa',
            accessToken,
        }

        return await this.getDataWithGeneralResponse(`/api/v2/complaint/view`, { complaintNumber }, headers)
    }

    async upsertComplaint(accessToken: string, complaint: UpsertComplaintRequest): Promise<GeneralResponse<ComplaintUpsertResponse>> {
        const headers = {
            platform: 'pwa',
        } as { [name: string]: string }

        if (accessToken && accessToken !== '') {
            headers.accessToken = accessToken
        }

        const fd = new FormData()
        fd.append('orderNumber', complaint.orderNumber)
        fd.append('shipping', complaint.shipping || '')
        fd.append('complaintType', String(complaint.complaintType))
        fd.append('complaintReason', String(complaint.complaintReason))
        fd.append('complaintNote', complaint.complaintNote === null ? '' : complaint.complaintNote)
        fd.append('productAmounts', JSON.stringify(complaint.productAmounts))
        fd.append('serviceId', complaint.complaintServiceId === null ? '' : String(complaint.complaintServiceId))
        fd.append('address', JSON.stringify(complaint.address))
        fd.append('bankAccount', JSON.stringify(complaint.bankAccount))
        fd.append('date', complaint.date || '')
        fd.append('slot', complaint.slot === null ? '' : complaint.slot)
        complaint.files?.forEach((file) => {
            fd.append('files[]', file)
        })

        return await this.getDataWithGeneralResponse(`/api/v2/complaint/upsert`, fd, headers)
    }

    async getOrdersForComplaint(accessToken: string): Promise<OrdersForComplaintResponse> {
        const headers = {
            platform: 'pwa',
            accessToken,
        }

        return await this.getData(`/api/v2/complaint/orders`, {}, headers)
    }

    async getComplaintSettings(): Promise<ComplaintSettingsResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(`/api/v2/complaint/settings`, {}, headers)
    }

    async getProductDetailDataAccess(id: number): Promise<ViewProductResponse> {
        return await this.getData(`/api/v2/product/view`, {
            id,
            uid: '',
            skipSaleAreas: true,
            skipSimilarProducts: true,
            skipDeliveries: true,
            showExpresData: true,
        })
    }

    async upsertProductBasket(
        uid: string,
        products: { productId: number; amount: number; voucherCode: string | null; hasAddonSalePrice?: boolean }[],
        accessToken: string,
        shouldAddCustomAddonSaleCarousels: boolean
    ) {
        const headers = {
            Accept: `application/vnd.pilulka.basket.lite+json;version=1.0`,
            platform: 'pwa',
            accessToken: '',
        }

        if (accessToken) {
            headers.accessToken = accessToken
        }

        return await this.getData(
            `/api/v2/basket/product/upsert`,
            {
                uid,
                products,
                shouldAddCustomAddonSaleCarousels,
            },
            headers
        )
    }

    async cartStep1Middleware(uid: string, accessToken: string): Promise<GeneralResponse<CartMiddlewareResponse>> {
        const headers = {
            platform: 'pwa',
            accessToken: '',
        }

        if (accessToken) {
            headers.accessToken = accessToken
        }

        return await this.getDataWithGeneralResponse(
            `/api/v2/middleware/cart-step-1`,
            {
                uid,
            },
            headers
        )
    }

    async cartStep2Middleware(uid: string, accessToken: string): Promise<GeneralResponse<CartMiddlewareResponse>> {
        const headers = {
            platform: 'pwa',
            accessToken: '',
        }

        if (accessToken) {
            headers.accessToken = accessToken
        }

        return await this.getDataWithGeneralResponse(
            `/api/v2/middleware/cart-step-2`,
            {
                uid,
            },
            headers
        )
    }

    async updateRaiffeisenDiscountCheckboxes(
        body: UpdateRaiffeisenDiscountCheckboxesRequest,
        headers: UpdateRaiffeisenDiscountCheckboxesRequestHeaders
    ) {
        const newHeaders = {
            platform: 'pwa',
            ...headers,
        }

        return await this.getData(`/api/v2/basket/raiffeisen-discount-checkboxes`, body, newHeaders)
    }

    async generateBasketUid(accessToken: string): Promise<string> {
        const headers = { platform: 'pwa', accessToken: '' }

        if (accessToken) {
            headers.accessToken = accessToken
        }

        const response = await this.getData(`/api/v2/basket/uid`, headers)

        return response.uid
    }

    async getProductDetailData(id: number) {
        const headers = {}
        return await this.getData(
            `/api/v2/product/view`,
            {
                id,
            },
            headers
        )
    }

    async getPharmacyProductsAvailability(id: number, name?: string): Promise<ViewPharmacyProductsAvailabilityResponse> {
        return await this.getData(`/api/v2/pharmacy/product/view`, {
            productId: id,
            pharmacyName: name,
        })
    }

    async getProductReviewlist(id: number): Promise<Review[]> {
        return await this.getData(`/api/v2/review-club/list`, {
            productId: id,
        })
    }

    async getProductReviewDetail(id: number): Promise<Review> {
        return await this.getData(`/api/v2/review-club/view`, {
            reviewId: id,
        })
    }

    async getBasketProductCarousels(uid: string, variant: string): Promise<GeneralResponse<BasketProductCarouselsResponse>> {
        return await this.getDataWithGeneralResponse(
            `/api/v2/basket/product-carousels`,
            {
                uid,
                variant,
            },
            {
                Accept: `application/vnd.pilulka.basket.carousels+json;version=1.0`,
            }
        )
    }

    async createBasketUser(request: CreateBasketUserRequest, accessToken: string | undefined | null) {
        const headers = {
            accessToken: accessToken || '',
        }

        return await this.getData(`/api/v2/basket/customer/insert`, request, headers)
    }

    async getBasket(uid: string): Promise<GeneralResponse<ViewBasketResponse>> {
        return await this.getDataWithGeneralResponse(
            `/api/v2/basket`,
            {
                uid,
            },
            {
                Accept: `application/vnd.pilulka.basket+json;version=1.0`,
            }
        )
    }

    async addBasketVoucher(uid: string, voucherCode: string): Promise<AddBasketVoucherSuccessResponse | AddBasketVoucherErrorResponse> {
        const response: GeneralResponse<AddBasketVoucherSuccessResponse | AddBasketVoucherErrorResponse> = await $fetch(
            this.constructUrl(`/api/v2/basket/voucher/insert`),
            {
                method: 'POST',
                headers: {},
                body: {
                    uid,
                    voucherCode,
                },
            }
        )

        return response.response
    }

    async deleteBasketVoucher(uid: string, voucherCode: string): Promise<void> {
        await this.getData(`/api/v2/basket/voucher/delete`, {
            uid,
            voucherCode,
        })
    }

    async useBasketCredits(uid: string, useCredits: boolean): Promise<void> {
        return await this.getData(`/api/v2/basket/credits/use`, {
            uid,
            useCredits,
        })
    }

    async getVouchers({ accessToken }: { accessToken: string }) {
        return await this.getData(`/api/v2/service-voucher/list`, {}, { accessToken })
    }

    async getProductsByIds(ids: number[], preserveIdsOrder: boolean) {
        const body = { ids, preserveIdsOrder }

        return await this.getData(`/api/v2/product/_by_ids`, body)
    }

    async getProductRatingByIds(ids: number[]): Promise<ProductRatingResponse> {
        const productIds = ids
        return await this.getData(`/api/v2/product/rating`, { productIds })
    }

    async addFavorite(id: number, { accessToken }: { accessToken: string }): Promise<FavoriteInsertOrDeleteResponse> {
        return await this.getData('/api/v2/favorite/insert', { id: [id] }, { accessToken })
    }

    async removeFavorite(id: number, { accessToken }: { accessToken: string }): Promise<FavoriteInsertOrDeleteResponse> {
        return await this.getData('/api/v2/favorite/delete', { id: [id] }, { accessToken })
    }

    async getComments({
        objectId,
        type,
        offset,
        limit,
        isVerifiedPurchase,
    }: {
        objectId: number
        type: number
        offset: number
        limit: number
        isVerifiedPurchase: boolean
    }) {
        return await this.getData(`/api/v2/review/list`, {
            objectId,
            type,
            from: offset,
            size: limit,
            isVerifiedPurchase: isVerifiedPurchase || false,
        })
    }

    async rateComment({ uid, isUseful, accessToken }: { uid: string; isUseful: boolean; accessToken: string }) {
        return await this.getData('/api/v2/review/isuseful', { uid, isUseful }, { accessToken })
    }

    async addComment(request: AddCommentRequest) {
        const headers = request.accessToken ? { accessToken: request.accessToken } : {}

        return await this.getData(
            `/api/v2/review/upsert`,
            {
                uid: request.uid,
                objectId: request.objectId,
                type: request.type,
                name: request.name,
                email: request.email,
                text: request.text,
                rating: request.rating,
                questionRatings: request.questionRatings,
                pros: request.pros,
                cons: request.cons,
                feedbackHash: request.feedbackHash,
            },
            headers
        )
    }

    async getReviewUserByFeedbackHash({ feedbackHash }: { feedbackHash: string }) {
        return await this.getData(`/api/v2/review/view-user`, {
            feedbackHash,
        })
    }

    async rateReviewClub({ reviewId, type, accessToken }: { reviewId: number; type: 'like' | 'dislike'; accessToken: string }) {
        return await this.getData('/api/v2/review-club/rate', { reviewId, type }, { accessToken })
    }

    async sendFeedback(request: SendFeedbackRequest) {
        const headers: any = {}
        if (request.accessToken) {
            headers.accessToken = request.accessToken
        }

        return await this.getData(
            `/api/v3/feedback/save`,
            {
                rating: request.rating,
                uid: request.uid,
                feedbackHash: request.feedbackHash,
                objectId: request.objectId,
                type: request.type,
                text: request.text,
            },
            headers
        )
    }

    async sendFormRecommend(data: { fullName: string; email: string; message: string }) {
        return await this.getData(`/api/v2/suggest-missing-product/send`, data)
    }

    async getSelectedDiscussionsList(
        productId: number,
        categoryId: number | null,
        { accessToken }: { accessToken: string | null }
    ): Promise<SelectedDiscussionList[]> {
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/discussion/list', { productId, categoryId }, headers)
    }

    saveCjEventId(query: LocationQuery, accessToken: string) {
        const { cjevent } = query

        if (!cjevent) {
            return
        }

        return $fetch(this.constructUrl(`/backend/save-event-id/${cjevent}`), {
            headers: { accessToken },
        })
    }

    async validateAndRegisterUser(email: string, agreement: boolean, newsletterDisagree: boolean): Promise<PopUpRegistrationResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/pop-up/registration',
            {
                email,
                agreement,
                newsletterDisagree,
            },
            headers
        )
    }

    async upsertWatchdogProduct(
        productId: number,
        email: string,
        agreement: boolean,
        newsletterDisagree: boolean
    ): Promise<WatchdogProductResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/product/watchdog/upsert',
            {
                productId,
                email,
                agreement,
                newsletterDisagree,
            },
            headers
        )
    }

    async renewWatchdogProduct(hash: string, renew: boolean): Promise<WatchdogProductResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/product/watchdog/renew',
            {
                hash,
                renew,
            },
            headers
        )
    }

    validatePostCode(postCode: string): Promise<any> {
        return $fetch(this.constructUrl(`/backend/pilulka-car/${postCode}`))
    }

    async validateEmail(email: string): Promise<{ exists: boolean; message: string }> {
        return await this.getData('/api/v2/validation/email', {
            email,
        })
    }

    async getSlots(postCode: string, delay: number): Promise<SlotsResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/complaint/pilulka-car-slots',
            {
                postCode,
                delayInDays: delay,
            },
            headers
        )
    }

    async discussionReport(requestBody: ReportRequestBody) {
        return await this.getData(`/api/v2/discussion/report`, {
            requestBody,
        })
    }

    async discussionUpsertWatchdog(productId: number, notificationFrequency: string, { accessToken }: { accessToken: string }) {
        const headers = accessToken ? { accessToken } : {}
        const requestData = { productId, notificationFrequency }

        return await this.getData('/api/v2/discussion/upsert-watchdog', requestData, headers)
    }

    async discussionUpsert(requestBody: DiscussionUpsertRequest, { accessToken }: { accessToken: string }) {
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/discussion/upsert', requestBody, headers)
    }

    async discussionGetWatchdog(productId: number, { accessToken }: { accessToken: string }): Promise<DiscussionGetWatchdog[]> {
        const headers = accessToken ? { accessToken } : {}
        const requestData = { productId }

        return await this.getData('/api/v2/discussion/get-watchdog', requestData, headers)
    }

    async discussionLikePost(postId: number, { accessToken }: { accessToken: string }): Promise<LikePost> {
        const requestData = { postId }
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/discussion/like', requestData, headers)
    }

    async discussionDislikePost(postId: number, { accessToken }: { accessToken: string }): Promise<DisLikePost> {
        const requestData = {
            postId,
        }
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/discussion/dislike', requestData, headers)
    }

    async getCategoryProductListBanners(id: number) {
        const requestData = {
            id,
        }

        return await this.getData('/api/v2/category/product-list-banners', requestData)
    }

    async getShopBanners(): Promise<PwaShopBannersResponse> {
        return await this.getData('/api/v2/pwa/shop-banners')
    }

    async getStaticPage(url: string) {
        const {
            headInfo,
            params: { slug },
        } = await this.getRouteProperties(url)

        const response = await this.getStaticPageWidgets(slug)

        return { ...response, headInfo }
    }

    logConsentChange(cookieName: string, status: 'granted' | 'denied', clientId: string | null = null) {
        this.getData('/api/v2/cookie/log/upsert', {
            cookieName,
            clientId,
            isAllowed: status === 'granted',
        })
    }

    getStaticPageWidgets(slug: string): Promise<ViewStaticContentResponse> {
        return this.getData('/api/v3/content/static/view', { slug })
    }

    getRouteProperties(url: string) {
        return this.getData('/api/v2/pwa/router', { url })
    }

    getCommentAggregate(id: Number, type: string): Promise<CommentAggregateResponse> {
        return this.getData('/api/v2/comment/aggregate', { id, type })
    }

    getArticleList(currentUrl: string, page: number, filter: Object, sort: number): Promise<GeneralResponse<ViewArticleListResponse>> {
        return this.getDataWithGeneralResponse('/api/v2/article/list', { page, currentUrl, filter, sort })
    }

    getPwaMenu() {
        const headers = {
            Accept: 'application/json;version=1.0',
        }

        return this.getData('/api/v2/pwa/menu', {}, headers)
    }

    async getPWAHomepageData({ accessToken, response }: { accessToken: string | null; response: string[] }) {
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/pwa/homepage', { response }, headers)
    }

    async listLastBoughtRecommendedProducts({
        accessToken,
    }: {
        accessToken: string
    }): Promise<GeneralResponse<ListLastBoughtRecommendedProductsResponse>> {
        const headers = { accessToken }

        return await this.getDataWithGeneralResponse('/api/v2/user/last-bought-recommended-products', {}, headers)
    }

    async viewBasketDeliveryDateText(uid: string): Promise<GeneralResponse<ViewBasketDeliveryDateTextResponse>> {
        return await this.getDataWithGeneralResponse('/api/v2/basket/delivery-date', { uid })
    }

    async validateBasketPostCode(
        postCode: string,
        uid: string,
        accessToken: string | null,
        validatePilulkaExpres: boolean = false
    ): Promise<GeneralResponse<ValidateBasketPostCodeResponse>> {
        const headers = accessToken ? { accessToken } : {}

        return await this.getDataWithGeneralResponse(
            `/api/v2/location/validate/postcode/${postCode}`,
            { uid, validatePilulkaExpres },
            headers
        )
    }

    async login(email: string, password: string, uid: string) {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/login',
            {
                email,
                password,
                uid,
            },
            headers
        )
    }

    async findComplaintOrder(orderNumber: string, lastName: string): Promise<ComplaintFindOrderResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/complaint/find-order',
            {
                orderNumber,
                lastName,
            },
            headers
        )
    }

    async getCsrProducts() {
        return await this.getData('/api/v2/product/csr/list')
    }

    async getAddonSaleBundles(
        productId: number,
        strategy: AddonSaleBundleStrategy,
        excludeProducts: number[] = []
    ): Promise<ProductAddonSaleBundleResponse> {
        return await this.getData(`/api/v2/product/addon-sale-bundles`, {
            id: productId,
            strategy,
            excludeProducts,
        })
    }

    async getGiftEventProducts(uid: string, giftEventIds: number[] = []): Promise<GiftEventProductsResponse> {
        return await this.getData(`/api/v2/product/gift-events/active-products`, {
            uid,
            giftEventIds,
        })
    }

    async getCustomHomepageAddonSaleCarousels(): Promise<
        {
            position: string
            products: Product[]
            title: string
            titleLink: string
        }[]
    > {
        const headers = {
            platform: 'pwa',
        }

        const response = await this.getData('/api/v2/home/view-partial', {
            partialName: 'customHomepageAddonSaleCarousels',
            headers,
        })

        return response.customHomepageAddonSaleCarousels
    }

    async getData(url: string, inputData = {}, headers: any = {}) {
        let response = null
        try {
            headers.platform = 'pwa'
            response = await $fetch(this.constructUrl(url), {
                method: 'POST',
                headers,
                body: inputData,
            })
        } catch (e: unknown) {
            if (e instanceof FetchError) {
                const errorMessage = `Data could not be loaded from url ${url}. InputData: ${JSON.stringify(inputData)}. ${e}. Response: ${
                    e.response
                }`
                this.sentry?.sentryCaptureException?.(errorMessage)

                throw new Error(errorMessage)
            }

            this.sentry?.sentryCaptureException?.(e)

            throw e
        }

        const data: any = response
        const result = data.result
        const responseData = data.response

        if (result) {
            return responseData
        } else if (data.code === 301) {
            throw new InvalidAccessTokenException('Invalid access token')
        } else if (data.code === 300 || data.code === 307) {
            return data
        } else {
            const Exception = data.code === 404 ? NotFoundError : Error

            throw new Exception(
                `Data could not be loaded from url ${url}. InputData: ${JSON.stringify(inputData)}. Message ${data.message}`
            )
        }
    }

    async getDataWithGeneralResponse<T>(url: string, inputData = {}, headers: any = {}): Promise<GeneralResponse<T>> {
        try {
            headers.platform = 'pwa'
            return await $fetch(this.constructUrl(url), {
                method: 'POST',
                headers,
                body: inputData,
            })
        } catch (e: unknown) {
            if (e instanceof FetchError) {
                this.sentry?.sentryCaptureException?.(e)

                return e.response?._data
            }

            this.sentry?.sentryCaptureException?.(e)

            throw e
        }
    }
}
